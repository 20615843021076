import 'reflect-metadata';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initializeUserAnalytics } from './common/metrics/InitializeUserAnalytics';
import App from './containers/App';
import './i18n';
import './index.css';
import { CommonTokenInjector } from '@smartsheet/design-tokens-react';
import '@smartsheet/lodestar-core/dist/fonts/fonts.css';
import { createdStore, createdHistory } from './store/ConfigureStore';

initializeUserAnalytics();

ReactDOM.render(
    <Provider store={createdStore}>
        <ConnectedRouter history={createdHistory}>
            <CommonTokenInjector themeKey="ursa-light" />
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement
);
