/* eslint @typescript-eslint/no-misused-promises:"off" */
import styled from '@emotion/styled';
import { Modal, ModalBody, ModalFooter, ModalFooterButton, ModalHeader } from '@smartsheet/lodestar-core/';
import { Discussion as DiscussionDto, SmartsheetUser, User } from '../../../../common/interfaces';
import { isEmpty, isNullOrEmptyString } from '../../../../common/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { createStructuredSelector } from 'reselect';
import alertIcon from '../../../../assets/images/alert/icon-info-circle-bluebigger.svg';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import { fullName } from '../../../../common/utils/FullName';
import { getImageUrl } from '../../../../common/utils/GetImageUrl';
import { getUserInitialCssClass } from '../../../../common/utils/GetUserInitialCssClass';
import { getUserInitials } from '../../../../common/utils/GetUserInitials';
import withSetAppError, { WithSetAppErrorProps } from '../../../../components/hoc/WithSetAppError';
import ModalWrapper from '../../../../components/Modal';
import Spinner, { Color, Size } from '../../../../components/Spinner';
import { SmartsheetUserMapper } from '../../../../http-clients/SmartsheetUserMapper';
import viewClient from '../../../../http-clients/View.client';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../../../store';
import { userSelector } from '../../../Auth/Selectors';
import { currentRowSheetIdLegacySelector } from '../../Selectors';
import { Actions } from '../Actions';
import { DisplayComment, UserProfileImageId } from './Comment.interface';
import CommentInput from './CommentInput';
import Discussion from './Discussion';

interface OwnProps {
    rowId: number;
    viewId: string;
    addComments: boolean;
    discussions?: DiscussionDto[];
    comment?: DisplayComment;
    width: number;
    onNewComment: () => void;
    onChangeIsDirty: (isDirty: boolean) => void;
    showModal: boolean;
}

interface StateProps {
    user: User;
    sheetId?: number;
}

interface DispatchProps {
    closeModalDetailsPanel: () => Actions;
}

type Props = OwnProps & WithSetAppErrorProps & StateProps & DispatchProps & LanguageElementsProp;

interface State {
    discussions: DiscussionDto[];
    isSaving: boolean;
    isDirty: boolean;
}

export class CommentLegacy extends React.Component<Props, State> {
    private userProfileImageIds: UserProfileImageId = {};
    private commentInput: HTMLInputElement | null;
    private currentCommentInput: HTMLInputElement | null;
    private discussionList: HTMLDivElement | null;
    private readonly currentSmartsheetUser: SmartsheetUser;
    private readonly unsentComments: Map<number, string>;
    private readonly editedComments: Map<number, string>;

    public constructor(props: Props) {
        super(props);
        this.state = {
            discussions: props.discussions || [],
            isSaving: false,
            isDirty: false,
        };

        // TODO: We should really consolidate this. Annoying that half our code uses User while the other half uses SmartsheetUser.
        this.currentSmartsheetUser = SmartsheetUserMapper.fromUser(this.props.user);
        this.unsentComments = new Map<number, string>();
        this.editedComments = new Map<number, string>();

        // Collect user's profile image ids
        this.collectUserProfileImageIds();
    }

    public componentDidMount(): void {
        this.currentCommentInput = this.commentInput;
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.rowId !== this.props.rowId) {
            this.setState({ discussions: this.props.discussions || [] });

            // Collect user's profile image ids
            this.collectUserProfileImageIds();
        } else if (this.props.discussions !== prevProps.discussions) {
            this.setState({ discussions: this.props.discussions || [] });
        }

        if (this.currentCommentInput) {
            this.currentCommentInput.focus();
            if (this.currentCommentInput === this.commentInput) {
                this.scrollToCurrent();
            }
        }
    }

    public render(): JSX.Element {
        const style = {};

        // TODO: Put userIcon in separate reusable component
        let userIcon: JSX.Element;

        if (this.props.user.email && this.props.addComments) {
            if (this.userProfileImageIds[this.props.user.email]) {
                userIcon = (
                    <div data-client-id={AutomationIds.COMMENT_COMMENTS_ICON} className="icon-user initials">
                        <img src={this.userProfileImageIds[this.props.user.email]} alt="icon of user's profile image" />
                    </div>
                );
            } else {
                const name = fullName(this.props.user) || this.props.user.email;
                const initials = getUserInitials(name);
                userIcon = (
                    <div
                        // FIXME: Why is this a type and not an ID?
                        data-client-type={AutomationIds.COMMENT_COMMENTS_ICON}
                        className={'icon-user initials ' + getUserInitialCssClass(initials)}
                    >
                        {initials}
                    </div>
                );
            }
        }
        return (
            <div className="comments-panel" style={style}>
                {!this.state.isSaving && (
                    <div
                        className="comments"
                        style={style}
                        ref={(div) => {
                            this.discussionList = div;
                        }}
                    >
                        {!isEmpty(this.state.discussions) &&
                            this.state.discussions.map((dis, index) => (
                                <Discussion
                                    key={index}
                                    viewId={this.props.viewId}
                                    rowId={this.props.rowId}
                                    reportSheetId={this.props.sheetId}
                                    discussion={dis}
                                    userProfileImageIds={this.userProfileImageIds}
                                    currentUser={this.currentSmartsheetUser}
                                    currentUserIcon={userIcon}
                                    userLocale={this.props.user.locale!}
                                    userTimeZone={this.props.user.timeZone!}
                                    // Event handlers for the comment menu options.
                                    onClickDeleteComment={this.handleClickDeleteComment}
                                    onClickDeleteDiscussion={this.handleClickDeleteDiscussion}
                                    // Event handlers for editing the root comment of the discussion
                                    onChangeExistingComment={this.handleChangeExistingComment}
                                    onSaveExistingComment={this.handleSaveExistingComment}
                                    // Flag and event handlers for adding replies.
                                    newCommentsAllowed={this.props.addComments}
                                    onSaveNewComment={this.handleSaveNewComment}
                                    onChangeNewComment={this.handleChangeNewComment}
                                />
                            ))}

                        {isEmpty(this.state.discussions) && <div className="comments empty">{this.props.languageElements.DETAIL_COMMENTS_EMPTY}</div>}

                        <div style={{ float: 'left', clear: 'both' }} />
                    </div>
                )}

                {!this.state.isSaving && this.props.addComments && (
                    <CommentInput
                        className={'add-comment-wrap comment-wrap'}
                        currentUser={this.currentSmartsheetUser}
                        currentUserIcon={userIcon!}
                        onSave={(text) => this.handleSaveNewComment(0, text)}
                        onChange={(text) => this.handleChangeNewComment(0, text)}
                        viewId={this.props.viewId}
                        rowId={this.props.rowId}
                        reportSheetId={this.props.sheetId}
                    />
                )}

                {this.state.isSaving && (
                    <ModalWrapper isModalOpen={true} onClose={() => {}} hideCloseButton={true} focusTrap={false}>
                        <Spinner label={this.props.languageElements.SPINNER_SAVING_LABEL} color={Color.BLUE} size={Size.MEDIUM} />
                    </ModalWrapper>
                )}

                <Modal isOpen={this.props.showModal} onCloseRequested={this.props.closeModalDetailsPanel} width={Size.MEDIUM}>
                    <ModalHeader
                        title={this.props.languageElements.DETAILS_DATA_MODAL_UNSAVED_CHANGES_TITLE}
                        titleIcon={<img src={alertIcon} alt="alert" />}
                        onCloseRequested={this.props.closeModalDetailsPanel}
                    />
                    <ModalBody>
                        <p>{this.props.languageElements.DETAILS_DATA_MODAL_UNSAVED_CHANGES_MESSAGE}</p>
                    </ModalBody>
                    <FullWidthFooter>
                        <LeftSideButton
                            appearance={'secondary'}
                            onClick={() => {
                                this.props.closeModalDetailsPanel();
                            }}
                        >
                            {this.props.languageElements.BUTTON_TEXT_CANCEL}
                        </LeftSideButton>
                        <SpecialFooterButton onClick={this.handleSendAllUnsentComments}>
                            {this.props.languageElements.BUTTON_TEXT_SAVE}
                        </SpecialFooterButton>
                    </FullWidthFooter>
                </Modal>

                <Prompt
                    message={this.props.languageElements.ADMIN_PANEL_PROMPT_UNSAVED_CHANGES_MESSAGE}
                    when={!this.props.showModal && this.state.isDirty}
                />
            </div>
        );
    }

    public handleSendAllUnsentComments = async () => {
        if (!this.state.isDirty) {
            this.props.closeModalDetailsPanel();

            return;
        }

        this.setState({ isSaving: true });
        try {
            const commentPromises: any[] = [];

            // Create an array of promises
            for (const [discussionId, text] of Array.from(this.unsentComments.entries())) {
                commentPromises.push(
                    discussionId === 0
                        ? viewClient.addDiscussion(this.props.viewId, this.props.rowId!, text, this.props.sheetId)
                        : viewClient.addComment(this.props.viewId, this.props.rowId!, text, discussionId, this.props.sheetId)
                );
            }

            for (const [commentId, text] of Array.from(this.editedComments.entries())) {
                commentPromises.push(viewClient.updateComment(this.props.viewId, this.props.rowId!, commentId, text, this.props.sheetId));
            }

            await Promise.all(commentPromises);

            this.setState({ isSaving: false }, () => {
                this.props.closeModalDetailsPanel();
                this.props.onNewComment();
            });
        } catch (error) {
            this.setState({ isSaving: false }, () => {
                this.props.closeModalDetailsPanel();
                this.props.onNewComment();
                this.props.onSetAppStageError(error);
            });
        }
    };

    private isDirty = () => this.editedComments.size > 0 || this.unsentComments.size > 0;

    /**
     * TODO: After app-core redesign changes the order of comments the scrolling needs to be revised:
     * 1) Drop the this.discussionList.scrollIntoView(true) statement below
     * 2) Reinstate below statement: this.discussionList.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
     * 3) Add this prop: ref={div => { this.discussionList = div; }} to above <div style={{ float: 'left', clear: 'both' }} />
     * 4) Remove the same prop from discussions div above
     */
    private scrollToCurrent = (): void => {
        if (this.discussionList) {
            this.discussionList.scrollIntoView(true);
            // this.discussionList.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
        }
    };

    private collectUserProfileImageIds = () => {
        this.state.discussions.forEach((discussion) => {
            discussion.comments.forEach(({ createdBy }) => {
                const { email, profileImage } = createdBy;
                if (typeof this.userProfileImageIds[email] === 'undefined' && profileImage) {
                    // In the API, the Image Proxy URL is accessible via an environment variable.  We don't have
                    // access to that in the APP. Instead, grab it from the currently logged-in users profile icon.
                    let imageProxyUrl = 'https://aws.smartsheet.com/storageProxy/image/images';
                    const { profileImgUrl } = this.props.user;
                    if (profileImgUrl) {
                        const imageIdIndex = profileImgUrl.lastIndexOf('/');
                        if (imageIdIndex >= 0) {
                            imageProxyUrl = profileImgUrl.substring(0, imageIdIndex);
                        }
                    }

                    this.userProfileImageIds[email] = getImageUrl(profileImage.imageId, imageProxyUrl);
                }
            });
        });
    };

    private handleAction = (action: () => Promise<any>) =>
        this.setState({ isSaving: true }, async () => {
            try {
                await action();
                this.setState({ isSaving: false }, () => this.props.onNewComment());
            } catch (error) {
                this.setState({ isSaving: false }, () => this.props.onSetAppStageError(error));
            }
        });

    /// region Comment Menu Event Handlers
    /**
     * Sends a request to the SMAR API to delete a specific comment.
     */
    private handleClickDeleteComment = (commentId: number) =>
        this.handleAction(() => viewClient.deleteComment(this.props.viewId, this.props.rowId, commentId, this.props.sheetId));

    /**
     * Sends a request to the SMAR API to delete a discussion (a thread of comments).
     *
     * Note: When a comment that has replies is deleted, the replies are unaffected. If the root comment of the
     *       discussion is deleted, the first reply becomes the new root comment. If the only comment in a discussion
     *       is deleted, then the API also removes the discussion, meaning the old discussion id is no longer valid.
     */
    private handleClickDeleteDiscussion = (discussionId: number) =>
        this.handleAction(() => viewClient.deleteDiscussion(this.props.viewId, this.props.rowId, discussionId, this.props.sheetId));
    /// endregion

    /// region Event handlers for existing comments
    /**
     * This method tracks comments that are currently being edited.
     */
    private handleChangeExistingComment = (commentId: number, text: string) => {
        const trimmedText = text.trim();
        if (trimmedText === '') {
            this.editedComments.delete(commentId);
        } else {
            this.editedComments.set(commentId, trimmedText);
        }

        const isDirty = this.isDirty();
        this.setState({ isDirty }, () => this.props.onChangeIsDirty(isDirty));
    };

    /**
     * Sends a request to the SMAR API for updating the text of a comment.
     * This method will immediately return If the comment text is an empty string.
     */
    private handleSaveExistingComment = (commentId: number, text: string) => {
        const trimmedText = text.trim();
        if (!isNullOrEmptyString(trimmedText)) {
            this.handleAction(() => viewClient.updateComment(this.props.viewId, this.props.rowId, commentId, trimmedText, this.props.sheetId));
        }
    };
    /// endregion

    /// region Event handlers for new comments (new discussions).
    private handleChangeNewComment = (discussionId: number, text: string): void => {
        // NOTE: A discussionId of zero denotes a new row level discussion.
        const trimmedText = text.trim();
        if (trimmedText === '') {
            this.unsentComments.delete(discussionId);
        } else {
            this.unsentComments.set(discussionId, trimmedText);
        }

        const isDirty = this.isDirty();
        this.setState({ isDirty }, () => this.props.onChangeIsDirty(isDirty));
    };

    /**
     * Called when user presses Enter or SEND icon in the REPLY or NEW COMMENT input boxes.
     */
    private handleSaveNewComment = (discussionId: number, text: string) => {
        const trimmedText = text.trim();
        if (isNullOrEmptyString(trimmedText)) {
            return;
        }

        // NOTE: A discussionId of zero denotes a new row level discussion.
        return this.handleAction(() =>
            discussionId === 0
                ? viewClient.addDiscussion(this.props.viewId, this.props.rowId, trimmedText, this.props.sheetId)
                : viewClient.addComment(this.props.viewId, this.props.rowId, trimmedText, discussionId, this.props.sheetId)
        );
    };

    /// endregion
}

const FullWidthFooter = styled(ModalFooter)`
    & > div {
        width: 100%;
    }
`;
const SpecialFooterButton = styled(ModalFooterButton)`
    flex: 0 0 auto;
`;
const LeftSideButton = styled(SpecialFooterButton)`
    margin-right: auto;
    margin-left: 0;
`;

const mapState = createStructuredSelector<StoreState, StateProps>({
    user: userSelector,
    sheetId: currentRowSheetIdLegacySelector,
});

const mapDispatch: DispatchProps = {
    closeModalDetailsPanel: Actions.closeModalDetailsPanel,
};

export default withLanguageElementsHOC(withSetAppError(connect<StateProps, DispatchProps>(mapState, mapDispatch)(CommentLegacy)));
