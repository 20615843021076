import 'reflect-metadata';
import { Column } from '@smartsheet/core-views-api-models';
import { DurationCellValue } from '@smartsheet/core-views-api-models/dist/esm/models/cell/CellValueType';
import { ColumnType, SymbolSetName } from '../../common/enums';
import { Format } from '../../common/interfaces';
import { CellType, ReportCell, ReportRow } from '../../common/interfaces/report';
import * as gsFormattingUtility from '../../common/utils/GsFormattingUtility';
import { getLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { columnTypeToSymbolSetNameMap, createSymbolOptionsIndexMap, SymbolImageUrlResolver } from '../Picklist/SymbolPickerImages';
import { innerCell } from './Cells/InnerCell';
import { GridRow } from './Grid.interface';

const isNonProdEnv = ['local', 'dev', 'staging'].some((env) => env === process.env.SMARTSHEET_ENV);
const symbolOptionsIndexMap = createSymbolOptionsIndexMap(getLanguageElements());

export const transformReportRowsToGridRows = ({
    rows,
    reportColumns,
    userLocale,
}: {
    rows: ReportRow[];
    reportColumns: Column[];
    userLocale?: string;
}): GridRow[] => {
    return rows.map((row) => {
        const rowData: GridRow = {
            id: row.id.toString(),
            gridId: row.gridId,
            format: row.format,
        };

        const cellMap = new Map(row.cells.map((cell) => [cell.reportColumnId, cell]));

        reportColumns.forEach((column) => {
            const cell = cellMap.get(column.id);
            rowData[column.id] = transformReportCellToGridCell({ cell, column, rowFormat: row.format, userLocale });
        });

        return rowData;
    });
};

const transformReportCellToGridCell = ({
    cell,
    column,
    rowFormat,
    userLocale,
}: {
    cell: ReportCell | undefined;
    column: Column;
    rowFormat: Format;
    userLocale?: string;
}): { innerCellComponent: JSX.Element | undefined } => {
    const symbol = getSymbol({ cell, column });
    const displayValue = getDisplayValue({ cell, column, userLocale });
    const cellFormatClassNames: string[] = gsFormattingUtility.getCellFormatClassNamesAll(
        cell?.format,
        cell?.conditionalFormat,
        undefined,
        rowFormat,
        true
    );

    const innerCellComponent = innerCell(displayValue, cellFormatClassNames, symbol);

    return { innerCellComponent };
};

const convertDurationToString = (duration: DurationCellValue): string => {
    const weeks = duration.weeks ? `${duration.weeks}w ` : '';
    const days = duration.days ? `${duration.days}d ` : '';
    const hours = duration.hours ? `${duration.hours}h ` : '';
    const minutes = duration.minutes ? `${duration.minutes}m ` : '';
    const seconds = duration.seconds ? `${duration.seconds}s ` : '';
    const milliseconds = duration.milliseconds ? `${duration.milliseconds}ms` : '';
    const negative = duration.negative ? '-' : '';
    const elapsed = duration.elapsed ? 'e' : '';

    return `${negative}${elapsed}${weeks}${days}${hours}${minutes}${seconds}${milliseconds}`.trim();
};

export const getSymbol = ({ cell, column }: { cell: ReportCell | undefined; column: Column }): string | undefined => {
    switch (cell?.cellValue?.cellType) {
        case CellType.PICKLISTTEXT: {
            const symbolSetName = columnTypeToSymbolSetNameMap.get(column.defaultType as ColumnType);
            if (!symbolSetName) {
                return;
            }

            const symbolOptionIndex = symbolOptionsIndexMap.get(`${symbolSetName}|${cell.cellValue.value}`);
            if (symbolOptionIndex == null) {
                return;
            }

            return SymbolImageUrlResolver(symbolSetName, symbolOptionIndex);
        }
        case CellType.CHECKBOX: {
            const symbolName = columnTypeToSymbolSetNameMap.get(column.defaultType as ColumnType) ?? SymbolSetName.NONE;
            return SymbolImageUrlResolver(symbolName, cell.cellValue.value ? 1 : 0);
        }
        default: {
            switch (column.defaultType) {
                case ColumnType.CHECKBOX:
                    return SymbolImageUrlResolver(SymbolSetName.NONE, 0);
                case ColumnType.FLAG:
                    return SymbolImageUrlResolver(SymbolSetName.FLAG, 0);
                case ColumnType.STAR:
                    return SymbolImageUrlResolver(SymbolSetName.STAR, 0);
                default:
                    return;
            }
        }
    }
};

export const getDisplayValue = ({ cell, column, userLocale }: { cell: ReportCell | undefined; column: Column; userLocale?: string }): string => {
    switch (cell?.cellValue?.cellType) {
        case CellType.NUMBER:
        case CellType.PICKLISTNUMBER:
            return gsFormattingUtility.getFormattedNumericValueAll(Number(cell.cellValue.value), cell.format, true);
        case CellType.TEXT:
        case CellType.SYS_DATE:
        case CellType.PICKLISTTEXT:
            return cell.cellValue.value;
        case CellType.CHECKBOX:
            return cell.cellValue.value.toString();
        case CellType.ABSTRACTDATETIME:
        case CellType.DATE:
            return gsFormattingUtility.getFormattedDate(cell.cellValue.value, cell.format, userLocale, true);
        case CellType.DURATION:
            return convertDurationToString(cell.cellValue);
        case CellType.MULTICONTACT:
            return cell.cellValue.contacts.map((contact) => contact.name).join(', ');
        case CellType.MULTIPICKLIST:
            return cell.cellValue.values.join(', ');
        case CellType.SYS_USER:
        case CellType.CONTACT:
            return cell.cellValue.name;
        default:
            if (cell?.cellValue) {
                // TODO: Removed in future task. This should never be reached
                return isNonProdEnv ? 'NOT IMPLEMENTED' : '';
            }

            switch (column.defaultType) {
                case ColumnType.CHECKBOX:
                case ColumnType.FLAG:
                case ColumnType.STAR:
                    return 'false';
                default:
                    return '';
            }
    }
};
