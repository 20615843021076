import { routerMiddleware } from 'connected-react-router';
import { AnyAction, applyMiddleware, compose, createStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { StoreState } from '.';
import rootEpic from './RootEpic';
import createRootReducer from './RootReducer';
import { createBrowserHistory, History } from 'history';

const epicMiddleware = createEpicMiddleware();

const configureStore = (history: History): Store<StoreState> => {
    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    // @ts-ignore: no-underscore-dangle
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // Create the store with two middlewares
    // 1. epicMiddleware: Makes redux-observable work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    const middlewares = [epicMiddleware, routerMiddleware(history)];

    const enhancers = [applyMiddleware(...middlewares)];

    const store = createStore<StoreState, AnyAction, unknown, unknown>(createRootReducer(history), composeEnhancers(...enhancers));

    epicMiddleware.run(rootEpic);

    return store;
};

export const createdHistory = createBrowserHistory();
export const createdStore: Store<StoreState> = configureStore(createdHistory);
