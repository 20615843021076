import { AxiosError } from 'axios';
import { createSelector } from 'reselect';
import { AsyncStatus, ViewSourceType } from '../../common/enums';
import * as dv from '../../common/interfaces';
import { AsyncResult, StoreState } from '../../store';
import { dvReportsDecoupleFeatureSelector } from '../App/Selectors';
import { selectedRowIdSelector } from './Details/Selectors';
import { reportDataSelector } from './Report/Selectors';
import { getRowSheetIdFromViewData } from './utils/ViewUtils';
import { ActEligibilityStatus } from '../../common/interfaces/eligibility/types';

const viewSelector = (state: StoreState) => state.view;

export const viewDataSelector = createSelector(viewSelector, (viewState) => viewState.viewData);
export const viewConfigSelector = createSelector(viewSelector, (viewState) => viewState.config);
export const viewEligibilitySelector = createSelector(viewSelector, (viewState) => viewState.eligibility);
export const isUserViewPlanEligibleSelector = createSelector(viewEligibilitySelector, (planInfo) => {
    if (planInfo.status !== AsyncStatus.DONE) {
        return false;
    }
    return [ActEligibilityStatus.ALREADY_PURCHASED, ActEligibilityStatus.PLAN_IS_ELIGIBLE, ActEligibilityStatus.ALREADY_IN_TRIAL].includes(
        planInfo.data.planEligibilityStatus
    );
});

export const viewIntakeSheetSelector = createSelector(viewConfigSelector, (viewConfig) => {
    if (viewConfig?.status === AsyncStatus.DONE) {
        return viewConfig.data.config?.intakeSheetId;
    }
    return;
});

export const rowSheetIdSelector = createSelector(
    viewDataSelector,
    (_: StoreState, rowId: string) => rowId,
    viewIntakeSheetSelector,
    (viewData, rowId, intakeSheetId) => getRowSheetIdFromViewData(viewData, rowId, intakeSheetId)
);

export const currentRowSheetIdLegacySelector = createSelector(
    viewDataSelector,
    selectedRowIdSelector,
    viewIntakeSheetSelector,
    (viewData: AsyncResult<dv.ViewData, AxiosError>, selectedRowId, intakeSheetId) =>
        getRowSheetIdFromViewData(viewData, selectedRowId, intakeSheetId) ?? undefined
);

export const currentRowSheetIdSelector = createSelector(
    viewDataSelector,
    selectedRowIdSelector,
    viewConfigSelector,
    dvReportsDecoupleFeatureSelector,
    reportDataSelector,
    (viewData: AsyncResult<dv.ViewData, AxiosError>, selectedRowId, configData, reportsDecoupleFeature, reportData) => {
        if (configData.status !== AsyncStatus.DONE) {
            return;
        }

        const { config, viewSourceType } = configData.data;

        if (!selectedRowId) {
            // when this is null, submit to intake sheet (new row insert)
            // TODO: For row inserts, Do not sheet ID is masked
            return config?.intakeSheetId ?? undefined;
        }

        if (reportsDecoupleFeature && viewSourceType === ViewSourceType.REPORT) {
            if (reportData.status === AsyncStatus.DONE || reportData.status === AsyncStatus.PARTIAL) {
                const selectedRow = reportData.data.rows.find((row) => row.id === selectedRowId);
                return selectedRow?.gridId;
            }
            return;
        }
        return getRowSheetIdFromViewData(viewData, selectedRowId, config?.intakeSheetId) ?? undefined;
    }
);
