import { HandleContacts } from '../../../../common/classes';
import { RowFieldFactory } from '../../../../common/classes/RowField';
import { AsyncStatus, ColumnType, HttpStatusCodes } from '../../../../common/enums';
import * as dv from '../../../../common/interfaces';
import { CellObjectValue, FormFieldInterface, Image, ImageUrlDto, RowData, SmartsheetUser } from '../../../../common/interfaces';
import { clearCellImageFields } from '../../../../common/interfaces/FormFieldInterface';
import { isNotUndefined } from '../../../../common/utils';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'reflect-metadata';
import FormFieldWrapper from '../../../../components/FormFieldWrapper/FormFieldWrapper';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import { imageExpirationsSelector, imageThumbnailsSelector, imageUrlsSelector } from '../../../../store/images/Selectors';
import { SubmittedForm } from '../../SubmittedForm';
import { Actions } from '../Actions';
import { Actions as ImageActions } from '../../../../store/images/Actions';
import { Actions as AppActions } from '../../../App/Actions';
import { Actions as ViewActions } from '../../../../containers/View/Actions';
import { CellImages } from '../CellImage/CellImages';
import './DetailsData.css';
import { useInitializeComponent } from '../useInitializeComponent';
import { DetailsDataError } from './DetailsDataError';
import DetailsDataFooter from './DetailsDataFooter';
import { checkFormFieldIsModified, getCellObjectValueFromField, getDetailsDataClassNames, getNullIfUndefined } from './DetailsDataUtils';
import { useState, useEffect, useCallback, useRef } from 'react';
import viewClient from '../../../../http-clients/View.client';
import { currentRowSheetIdSelector } from '../../Selectors';
import { latestCurrentRowUpsertSelector, selectStatusForCurrentRow, selectDetailsIsDirty } from '../Selectors';
import { RowRemovedFromView } from '../RowRemovedFromView';
import { mapContactColumns } from '../DetailsUtils';
import { mapSubmittedFormToFormFieldInterfaces } from '../MapSubmittedFormToFormFieldInterfaces';
import { DetailsPanelTabType } from '../../../../common/enums/DetailsPanelTabType.enum';
import { Modal } from '@smartsheet/lodestar-core';
import { Prompt } from 'react-router';
import { SavingData } from './SavingData';
import { LoadingData } from '../LoadingData';
import { loggingClient } from '../../../../http-clients/Logging.client';
import { isAxiosErrorWithResponse } from '../../../../common/utils/isAxiosErrorWithResponse';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import { InvalidModalContent } from './InvalidChangesModalContent';
import { UnsavedChangesModalContent } from './UnsavedChangesModalContent';

export interface DetailsDataProps {
    smartsheetUsers: dv.IPaginatedResult<SmartsheetUser>;
    viewId: string;
    rowId?: string;
    showModal: boolean;
    submittedForm?: SubmittedForm;
    initialTab?: DetailsPanelTabType;
    activeTab: boolean;
    isNewSubmission: boolean;
    isUnmaskedId: boolean;
}

const FILENAME = 'DetailsData.tsx';

export const DETAILS_PANEL_HEADER_HEIGHT = 72;

const handleContacts = new HandleContacts();

const DetailsData = ({ rowId, viewId, showModal, smartsheetUsers, activeTab, isNewSubmission, isUnmaskedId }: DetailsDataProps) => {
    const languageElements = useLanguageElements();
    const dispatch = useDispatch();
    const serverFormRef = useRef<FormFieldInterface[]>([]);

    // Selectors
    const sheetId = useSelector(currentRowSheetIdSelector);
    const imageUrls = useSelector(imageUrlsSelector);
    const imageThumbnails = useSelector(imageThumbnailsSelector);
    const imageExpirations = useSelector(imageExpirationsSelector);
    const rowUpsert = useSelector(latestCurrentRowUpsertSelector);
    const saveStatus = useSelector(selectStatusForCurrentRow);
    const isDirty = useSelector(selectDetailsIsDirty);

    // Component State
    const [currentForm, setCurrentForm] = useState<SubmittedForm>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isRowRemovedFromView, setIsRowRemovedFromView] = useState(false);

    const setIsDirty = useCallback((target: boolean) => dispatch(Actions.setDetailsDirtyState(target)), [dispatch]);

    const cellImages: CellImages = React.useMemo(() => {
        return {
            attachedForUpload: new Map<FormFieldInterface['columnId'], File>(),
            altText: new Map<FormFieldInterface['columnId'], string>(),
            scheduledForRemoval: new Map<FormFieldInterface['columnId'], Image>(),
        };
    }, []);

    const checkFormFieldsAreModified = useCallback(
        (currentData: SubmittedForm): boolean => {
            return (
                cellImages.attachedForUpload.size > 0 ||
                cellImages.scheduledForRemoval.size > 0 ||
                cellImages.altText.size > 0 ||
                (serverFormRef.current.some((formField: FormFieldInterface) => checkFormFieldIsModified(formField, currentData)) ?? false)
            );
        },
        [cellImages.altText.size, cellImages.attachedForUpload.size, cellImages.scheduledForRemoval.size]
    );

    const checkFormFieldIsValid = useCallback(
        (formField: FormFieldInterface, currentData: SubmittedForm): boolean => {
            if (!currentData.hasOwnProperty(formField.columnId)) {
                return true;
            }

            let currentValue = currentData[formField.columnId];

            // Ignore empty white space input before checking if req'd field is valid
            if (typeof currentValue === 'string') {
                currentValue = currentValue.trim();
            }

            // Check that all required fields are filled (used == to catch undefined and null, but ignore booleans)
            if (formField.required && (currentValue == null || currentValue === '')) {
                // When a user attaches an image, the "value" of the field will be cleared out.
                // If the field contains an image along with some alt text, then consider it valid.
                const containsImage = cellImages.attachedForUpload.has(formField.columnId);
                const imageHasAltText = cellImages.altText.has(formField.columnId);
                return containsImage && imageHasAltText;
            }

            if (!checkFormFieldIsModified(formField, currentData)) {
                return true;
            }

            let value;
            let objectValue;

            if (formField.type && [ColumnType.MULTI_CONTACT_LIST, ColumnType.MULTI_PICKLIST].includes(formField.type)) {
                objectValue = getNullIfUndefined(currentData[formField.columnId]);
            } else {
                value = getNullIfUndefined(currentData[formField.columnId]);
            }

            const rowField = RowFieldFactory.create(formField, { value, objectValue });
            return rowField.isValid;
        },
        [cellImages.altText, cellImages.attachedForUpload]
    );

    const checkFormFieldsAreValid = useCallback(
        (currentData: SubmittedForm): boolean => {
            if (serverFormRef.current.length === 0) {
                return true;
            }
            return serverFormRef.current.every((formField) => checkFormFieldIsValid(formField, currentData));
        },
        [checkFormFieldIsValid]
    );

    const getFormFieldsObject = useCallback(
        (form: FormFieldInterface[]): SubmittedForm => {
            if (form.length === 0) {
                return {};
            }

            const formFieldsObject: SubmittedForm = {};
            form.forEach((field: FormFieldInterface) => (formFieldsObject[field.columnId] = getCellObjectValueFromField(field, isNewSubmission)));

            return formFieldsObject;
        },
        [isNewSubmission]
    );

    const serverFormContactsMemo = useCallback(
        (serverForm) => {
            if (serverForm.length === 0) {
                return [];
            }
            return mapContactColumns(serverForm, smartsheetUsers.data);
        },
        [smartsheetUsers.data]
    );

    const setInitialState = useCallback(
        (serverForm: FormFieldInterface[]) => {
            serverFormRef.current = serverForm;
            setCurrentForm(getFormFieldsObject(serverFormContactsMemo(serverForm)));
            setIsRowRemovedFromView(false);
            setIsValid(true);
            setIsDirty(false);
        },
        [getFormFieldsObject, serverFormContactsMemo, setIsDirty]
    );

    const fetchRowData = useCallback(async () => {
        let rowData: RowData;
        try {
            setIsLoading(true);

            if (rowId == null) {
                rowData = await viewClient.getViewForm(viewId);
            } else {
                rowData = await viewClient.getRowForm({ viewId, rowId, reportSheetId: sheetId, isUnmaskedId });
            }

            setInitialState(rowData?.form ?? []);
            setIsLoading(false);

            if (serverFormRef.current.length === 0) {
                return;
            }

            const formFieldsWithCellImages = serverFormRef.current.filter((formField) => formField.image);
            if (formFieldsWithCellImages.length) {
                const images = formFieldsWithCellImages.map((formField) => formField.image).filter(isNotUndefined);
                dispatch(ImageActions.fetchImageUrls(images));
            }
        } catch (error) {
            if (isAxiosErrorWithResponse(error) && error.response!.status === HttpStatusCodes.NOT_FOUND) {
                // If a 404 (Not Found) error is returned, then the row no longer exists in the view
                loggingClient.logInfo({
                    file: FILENAME,
                    message: `Getting row data failed, row not in the view`,
                    viewId,
                    rowId,
                });

                if (rowId) {
                    dispatch(ViewActions.removeGridRow(viewId, rowId));
                }
            } else {
                loggingClient.logError(FILENAME, 'getRowData', error, {
                    viewId,
                    rowId,
                });

                setIsLoading(false);
                dispatch(AppActions.setAppStageError(error));
            }
        }
    }, [dispatch, rowId, setInitialState, sheetId, viewId]);

    const initializeComponent = useCallback(
        (mounting: boolean) => {
            if (!mounting) {
                setInitialState([]);
            }

            fetchRowData();
        },
        [fetchRowData, setInitialState]
    );

    useInitializeComponent(rowId, isNewSubmission, initializeComponent);

    useEffect(() => {
        if (rowUpsert === undefined || saveStatus !== AsyncStatus.IN_PROGRESS) {
            return;
        }

        const { updatedForm } = rowUpsert;

        // if the save was successful and the form is returned as undefined, we will render <RowRemovedFromView> instead of the form.
        if (Object.keys(rowUpsert).includes('updatedForm') && updatedForm === undefined) {
            setIsRowRemovedFromView(true);
        }

        if (updatedForm !== undefined) {
            serverFormRef.current = updatedForm;
            setCurrentForm(getFormFieldsObject(serverFormContactsMemo(updatedForm)));
        }
    }, [saveStatus, rowUpsert, getFormFieldsObject, serverFormContactsMemo]);

    const handleAltTextChange = (field: FormFieldInterface, altText: string) => {
        if (field.image?.altText !== altText) {
            cellImages.altText.set(field.columnId, altText);
        } else {
            cellImages.altText.delete(field.columnId);
        }
        setIsDirty(cellImages.altText.size > 0);
    };

    const handleInsertImage = (field: FormFieldInterface, file: File) => {
        // Attach the file for upload.
        cellImages.attachedForUpload.set(field.columnId, file);

        // Attaching a new cell image will automatically overwrite other cell images.
        cellImages.scheduledForRemoval.delete(field.columnId);

        // Use the file's name as the initial altText value.  Users might change it before saving row changes.
        cellImages.altText.set(field.columnId, file.name);
        field.image = { altText: file.name };

        // Reset the tracked cell object value since cell images are tracked differently.
        handleChange(field.columnId, getCellObjectValueFromField(field, false));

        setIsDirty(true);
    };

    const handleClearContent = (field: dv.FormFieldInterface) => {
        // If the field contains a previously uploaded cell image, track the pending removal
        if (field.image) {
            cellImages.scheduledForRemoval.set(field.columnId, field.image);
        }
        // Remove any (not yet uploaded) cell image that had been attached.
        cellImages.attachedForUpload.delete(field.columnId);

        // Clean up any cell image related properties from the field.
        clearCellImageFields(field);

        handleChange(field.columnId, null);
    };

    const handleSave = () => {
        if (!isDirty) {
            return;
        }

        dispatch(Actions.closeModalDetailsPanel());
        const serverFormContacts = serverFormContactsMemo(serverFormRef.current);
        if (serverFormContacts.length === 0) {
            return;
        }
        // This scrollTo readjusts display on mobile devices
        window.scrollTo(0, 0);

        if (rowId == null) {
            let mappedFormFields = mapSubmittedFormToFormFieldInterfaces(serverFormContacts, currentForm, cellImages);

            // If no data is being saved in the new row, and we have cell images to attach,
            // then use the alt text for the cell images for the text values of the fields.
            // This will allow the new row to be saved, since otherwise reports make blank rows
            // inaccessible to the view, and then attaching the images to the cell in the new row would fail.
            if (mappedFormFields.length < 1 && cellImages.altText.size > 0) {
                const submittedFormForAltText: SubmittedForm = {};
                (cellImages.altText as Map<number, CellObjectValue>).forEach((altText, columnId) => (submittedFormForAltText[columnId] = altText));
                const emptyCellImages: CellImages = { attachedForUpload: new Map(), altText: new Map(), scheduledForRemoval: new Map() };
                mappedFormFields = mapSubmittedFormToFormFieldInterfaces(serverFormContacts, submittedFormForAltText, emptyCellImages);
            }

            // In the scenario where the initial insert request fails, the selectRowId will be populated with a temp ID.
            // As a consequence, on the retry of the insert a check is also performed to see if the row upsert has the isNewSubmission prop
            // set to true.
            dispatch(Actions.insertViewRow({ viewId, submittedForm: mappedFormFields, cellImages }));
        } else {
            const mappedFormFields = mapSubmittedFormToFormFieldInterfaces(serverFormContacts, currentForm, cellImages);
            dispatch(
                Actions.updateViewRow({
                    viewId,
                    rowId,
                    originalForm: serverFormContacts,
                    submittedForm: mappedFormFields,
                    cellImages,
                })
            );
        }
        // This stops a modal informing a user of the dirty state from appearing when a user tries to click on another row
        setIsDirty(false);
    };

    const handleSetImageUrlFields = (field: FormFieldInterface) => {
        const getCurrentImageUrl = (
            imageId: string,
            imageMap: Map<ImageUrlDto['imageId'], ImageUrlDto>,
            handlerAction: typeof ImageActions.clearImageUrl | typeof ImageActions.clearThumbnailUrl
        ) => {
            const imageUrl = imageMap.get(imageId);
            if (!imageUrl) {
                return;
            }

            // Image urls are temporary, so we need to make sure that the url has not expired.
            const urlExpiration = imageExpirations.get(imageUrl.url!);
            const isExpired = urlExpiration && new Date() > urlExpiration;
            if (isExpired) {
                dispatch(handlerAction(imageId));
                return;
            }
            return imageUrl;
        };

        if (field.image?.id) {
            field.thumbnailUrl = getCurrentImageUrl(field.image.id, imageThumbnails, ImageActions.clearThumbnailUrl);
            field.imageUrl = getCurrentImageUrl(field.image.id, imageUrls, ImageActions.clearImageUrl);
        }
    };

    const handleCancel = () => {
        if (rowId != null && saveStatus === AsyncStatus.ERROR) {
            // When a user discards the changes for a failed upsert request, reset the row upsert request property. This causes
            // the error message to disappear on the details panel and the view screen. No need to set state because the resetViewRowUpsertRequest
            // will trigger this component to update that in turn causes the state to be set
            dispatch(Actions.dismissErrorsForRow(rowId));
            // in this new version, we want to reset the data to the initial state for a new row
        } else {
            // Clear the map that tracks cell images attached for upload.
            cellImages.attachedForUpload.clear();

            // Clear the map that tracks altText changes.
            cellImages.altText.clear();

            // Before clearing the map that tracks what cell images were marked for removal,
            // we need to restore the image property for those fields.
            serverFormRef.current?.forEach((field) => {
                const image = cellImages.scheduledForRemoval.get(field.columnId);
                if (image) {
                    field.image = image;
                    handleSetImageUrlFields(field);
                    cellImages.scheduledForRemoval.delete(field.columnId);
                }
            });
        }
        setInitialState(serverFormRef.current);
    };

    const handleChange = (columnId: number, value: CellObjectValue) => {
        setCurrentForm((prevState) => {
            const updatedState = { ...prevState, [columnId]: value };

            // test data and maybe set modified
            // two effects to run when form is edited to update states
            setIsDirty(checkFormFieldsAreModified(updatedState));
            setIsValid(checkFormFieldsAreValid(updatedState));

            return updatedState;
        });
    };

    const handleCloseModal = () => dispatch(Actions.closeModalDetailsPanel());

    // Render components when the tab is active. We still want the component to execute the code above this point, just not render anything.
    if (!activeTab) {
        return null;
    }

    if (isRowRemovedFromView) {
        return <RowRemovedFromView />;
    }

    const detailsDataRef = React.createRef<HTMLDivElement>();
    const serverFormWithContacts = serverFormContactsMemo(serverFormRef.current);
    return (
        <>
            {isLoading && <LoadingData />}
            <div className="details-data" data-client-id={AutomationIds.DETAILS_DATA}>
                <div ref={detailsDataRef} className={getDetailsDataClassNames(isDirty, saveStatus)}>
                    {serverFormWithContacts
                        .map((field: FormFieldInterface, index: number) => {
                            const { columnId } = field;
                            return (
                                <FormFieldWrapper
                                    key={columnId}
                                    inputIndex={index}
                                    field={field}
                                    value={currentForm[columnId]}
                                    hyperlink={field.hyperlink}
                                    columnId={columnId}
                                    cellImages={cellImages}
                                    onInsertImage={handleInsertImage}
                                    onSetImageUrlFields={handleSetImageUrlFields}
                                    onAltTextChange={handleAltTextChange}
                                    onClearContent={handleClearContent}
                                    onChange={handleChange}
                                    isSettingsMode={false}
                                    isNewSubmission={isNewSubmission}
                                    onGetContactListValue={handleContacts.getContactListValue}
                                    onGetMultiContactsIfValid={handleContacts.getMultiContactsIfValid}
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    customLabel={field.customLabel}
                                    displayValue={field.displayValue}
                                    format={field.format}
                                    required={field.required}
                                    multiline={field.multiline}
                                    description={field.description}
                                    objectValue={field.objectValue}
                                    title={field.title}
                                    options={field.options}
                                    symbol={field.symbol}
                                    readOnly={field.readOnly}
                                    systemColumnType={field.systemColumnType}
                                    contactOptions={field.contactOptions}
                                    checked={field.checked}
                                    validation={field.validation}
                                    image={field.image}
                                    thumbnailUrl={field.thumbnailUrl}
                                    detailsDataRef={detailsDataRef}
                                />
                            );
                        })
                        .filter(isNotUndefined)}
                </div>
                <DetailsDataError
                    getRowData={() => {
                        fetchRowData();
                    }}
                    isNewSubmission={isNewSubmission}
                />
                <DetailsDataFooter
                    isDirty={isDirty}
                    isValid={isValid && saveStatus !== AsyncStatus.ERROR}
                    isSaving={saveStatus === AsyncStatus.NOT_STARTED}
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            </div>
            <SavingData rowId={rowId} saveStatus={saveStatus} isNewSubmission={isNewSubmission} />
            <Modal isOpen={showModal} onCloseRequested={handleCloseModal}>
                {isValid ? (
                    <UnsavedChangesModalContent onCloseModalDetailsPanel={handleCloseModal} onCancel={handleCancel} onSave={handleSave} />
                ) : (
                    <InvalidModalContent onCloseModalDetailsPanel={handleCloseModal} />
                )}
            </Modal>
            <Prompt message={languageElements.ADMIN_PANEL_PROMPT_UNSAVED_CHANGES_MESSAGE} when={!showModal && isDirty} />
        </>
    );
};

export default DetailsData;
