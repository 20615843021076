import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorType, HttpStatusCodes } from '../common/enums';

export const DEFAULT_MAX_RETRY_ATTEMPTS = 2;
export const BACKOFF_DELAY = 500;
export const RETRY_SMAR_ERROR_CODES = [ErrorType.SMARTSHEET_UNEXPECTED_ERROR_OR_LOCKED_TOKEN];

export const generateBackoffDuration = (delay: number, attempt: number): number => {
    // Generate exponential backoff duration
    const base = 2;
    const expoBackoffDuration = delay * Math.pow(base, attempt);

    // Add jitter to the duration, this improves the performance of a system by adding randomness. Check out the following link for more
    // information, https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
    const min = expoBackoffDuration - delay;
    const max = expoBackoffDuration + delay;

    // The maximum is inclusive and the minimum is inclusive
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Determines if the request should be retried based on the error code, status code, and axios error code.
 */
export const shouldRetry = (errorCode: number | undefined, statusCode: number | undefined) => {
    const retrySmarErrorCodes = RETRY_SMAR_ERROR_CODES.some((catchSmartsheetErrorCode) => catchSmartsheetErrorCode === errorCode);
    const retryStatusCode = statusCode === HttpStatusCodes.INTERNAL_SERVER_ERROR;
    return retrySmarErrorCodes && retryStatusCode;
};

export const createUrlPath = (path: string = '') => {
    return `${process.env.PUBLIC_URL || ''}${path}`;
};

export const createAxiosInstance = (params?: {
    interceptors: {
        onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
        onError: (error: AxiosError) => void;
    };
}): AxiosInstance => {
    const newAxiosInstance = axios.create({
        baseURL: createUrlPath('/api'),
        timeout: 180000,
    });

    newAxiosInstance.interceptors.request.use(
        (config) => {
            config.headers['Cache-Control'] = 'no-cache';

            // Retrieve the x-smar-xsrf token from session storage
            const xsrfToken = window.sessionStorage.getItem('x-smar-xsrf');
            if (xsrfToken) {
                config.headers['x-smar-xsrf'] = xsrfToken;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    if (params) {
        newAxiosInstance.interceptors.response.use(params.interceptors.onSuccess, params.interceptors.onError);
    }

    return newAxiosInstance;
};

export const createAxiosConfig = (params?: { queryParams?: { isUnmaskedId?: boolean; reportSheetId?: number } }): AxiosRequestConfig | undefined => {
    if (!params || !params.queryParams) {
        return;
    }

    return {
        params: {
            isumkd: params.queryParams.isUnmaskedId,
            rsid: params.queryParams.reportSheetId,
        },
    };
};
