import styled from '@emotion/styled';
import { colors, sizes } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import iconClose from '../../assets/images/close-button.svg';
import settingsIcon from '../../assets/images/icons/settings-blue.svg';
import { AccessLevel, AsyncStatus } from '../../common/enums';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { UserViewSettings } from '../../common/enums/UserViewSettings.enum';
import * as dv from '../../common/interfaces';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { OptionRepository } from '../../common/utils/OptionRepository';
import { SymbolLookup } from '../../common/utils/ViewSource';
import { Grid } from '../../components/Grid';
import RSidePanel from '../../components/RSidePanel';
import Spinner, { Color, Size } from '../../components/Spinner';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { AsyncResultDone } from '../../store';
import { Actions } from '../App/Actions';
import { iframeStatusSelector } from '../App/Selectors';
import { isViewPlanOrUserEligible } from '../Auth/Selectors';
import * as ViewActions from './Actions';
import Description from './Description';
import * as DetailsPanelActions from './Details/Actions';
import { Details } from './Details/Details';
import { DismissErrors } from './DismissErrors/DismissErrors';
import FilterContainer from './Filter/FilterContainer';
import * as ReportActions from './Report/Actions';
import ToggleFormats from './ToggleFormats';
import ToggleWrapText from './ToggleWrapText';
import { VerticalRule } from './VerticalRule';
import './View.css';
import ViewHeader from './ViewHeader';
import { ViewStatus } from './ViewStatus/ViewStatus';
import useDocumentTitle from './useDocumentTitle';
import { isUserViewPlanEligibleSelector } from './Selectors';
import { selectedRowIdSelector } from './Details/Selectors';

interface ViewContainerProps {
    viewConfig: AsyncResultDone<dv.ViewWithOwnerAndUserDetails>;
}

const CONTAINER_WIDTH = 300;

enum RightPanelRenderType {
    NONE,
    DETAILS,
    DESCRIPTION,
}

const ViewContainer = ({ viewConfig }: ViewContainerProps) => {
    const dispatch = useDispatch();
    const languageElements = useLanguageElements();
    const dataLoadingMessage = languageElements.HOME_TABLE_DATA_LOADING_MESSAGE;
    const [rightPanelRenderType, setRightPanelRenderType] = useState(RightPanelRenderType.NONE);
    const [wrapText, setWrapText] = useState(false);
    const [showFormats, setShowFormats] = useState(false);

    const inIframe = useSelector(iframeStatusSelector);
    const isLicensedUser = useSelector(isViewPlanOrUserEligible);
    const isUserViewPlanEligible = useSelector(isUserViewPlanEligibleSelector);
    const selectedRowId = useSelector(selectedRowIdSelector);

    useEffect(() => {
        // Add view details to user analytics
        UserAnalyticsGlobalContext.addViewData(viewConfig.data);
        dispatch(ViewActions.Actions.fetchEligibility(viewConfig.data.id));
        // This removes the loading spinner. This is necessary because the loading spinner is shown when the view is first loaded.
        dispatch(Actions.resetAppStage());

        return () => {
            // Reset the view report state
            dispatch(ReportActions.Actions.resetReportState());

            // Remove view details from user analytics
            UserAnalyticsGlobalContext.removeViewData();
        };
    }, [dispatch, viewConfig.data]);

    useEffect(() => {
        if (selectedRowId) {
            setRightPanelRenderType(RightPanelRenderType.DETAILS);
        } else {
            setRightPanelRenderType(RightPanelRenderType.NONE);
        }
    }, [selectedRowId]);

    const isIntakeSheetIdSet = viewConfig.data.config?.intakeSheetId;
    const currentUserAccessLevel = viewConfig.data.currentUserDetails.accessLevel ?? AccessLevel.NONE;

    const handleClickViewInfo = (): void => {
        setRightPanelRenderType(RightPanelRenderType.DESCRIPTION);
    };

    const handleCloseDetailsPanel = (): void => {
        // This scrollTo readjusts display on mobile devices
        window.scrollTo(0, 0);
        dispatch(DetailsPanelActions.Actions.unselectRow());
    };

    const handleCloseDescriptionPanel = (): void => {
        // This scrollTo readjusts display on mobile devices
        window.scrollTo(0, 0);
        setRightPanelRenderType(RightPanelRenderType.NONE);
    };

    const handleWrapTextSelect = (e: SyntheticEvent): void => {
        const toggledWrapText = !wrapText;
        OptionRepository.setOption(viewConfig.data.id, UserViewSettings.WRAP_TEXT, toggledWrapText);

        setWrapText(toggledWrapText);
    };

    const handleShowFormatsSelect = (e: SyntheticEvent): void => {
        const toggledShowFormats = !showFormats;
        OptionRepository.setOption(viewConfig.data.id, UserViewSettings.SHOW_FORMATS, toggledShowFormats);

        setShowFormats(toggledShowFormats);
    };
    useDocumentTitle(viewConfig.data.name);

    const handleClickNewButton = (): void => {
        // TODO: implement as part of 8.1e
    };

    const isUserAdminOrOwner = currentUserAccessLevel === AccessLevel.OWNER || currentUserAccessLevel === AccessLevel.ADMIN;
    const showSettingsIcon = !inIframe && isUserViewPlanEligible && isUserAdminOrOwner;

    // TODO: Fix smartsheetUsers on Details panel as part of DV-1001
    return (
        <div className="grid-container">
            <div className="grid-view-header">
                <ViewHeader viewName={viewConfig.data.name} viewId={viewConfig.data.id} onClickViewInfo={handleClickViewInfo} />
                {viewConfig.status === AsyncStatus.PARTIAL ? (
                    <SpinnerWrapper>
                        <Spinner color={Color.BLUE} size={Size.XSMALL} />
                        <SpinnerLabel>{dataLoadingMessage}</SpinnerLabel>
                    </SpinnerWrapper>
                ) : null}
            </div>
            <div className="grid-view-sub-header">
                <div className="left-header-items">
                    {/* Adding filter button with dummy values and keeping it disabled until we enable true filtering i.e,*/}
                    {/* filtering on server side instead of client side*/}
                    <FilterContainer
                        fields={[]}
                        filters={[]}
                        filterModalOpen={false}
                        activeFilterIndex={0}
                        editFilterIndex={0}
                        filterDisabled={true}
                        totalRows={0}
                        filteredRowCount={0}
                        onClickFilterIcon={() => {}}
                        onClickFilterMenu={() => {}}
                        onCancelFilter={() => {}}
                        onDeleteFilter={(): Promise<boolean> => {
                            return Promise.resolve(false);
                        }}
                        onApplyFilter={(): Promise<boolean> => {
                            return Promise.resolve(false);
                        }}
                        onFilterModalClose={() => {}}
                        picklistSymbolImageMap={new Map<string, SymbolLookup | undefined>()}
                        allFilterItemsMap={new Map<number, Map<string, any>>()}
                        filterMenuOpen={false}
                        onNewFilter={() => {}}
                        onFilterOff={() => {}}
                        onFilterSelect={() => {}}
                        onFilterEdit={() => {}}
                        onFilterDuplicate={() => {}}
                        isNewFilter={false}
                        isDuplicateFilter={false}
                        currentUserAccessLevel={AccessLevel.NONE}
                        isLoadingData={false}
                        isVisibleLoadingIndicator={false}
                        onClickFilterButton={() => {}}
                    />
                    <VerticalRule />
                    <ToggleFormats onClick={handleShowFormatsSelect} showFormats={showFormats} />
                    <VerticalRule />
                    <ToggleWrapText onClick={handleWrapTextSelect} wrapText={wrapText} />
                    <ViewStatus />
                    <DismissErrors />
                </div>
                <div className="right-header-items">
                    {showSettingsIcon && (
                        <div className="settings" data-client-id={AutomationIds.VIEW_SETTINGS}>
                            <Link to={`/views/${viewConfig.data.id}/admin/basic`} className={'link'}>
                                <img src={settingsIcon} alt="view configuration settings" />
                            </Link>
                            <div className="tooltip">
                                <div>{languageElements.VIEW_TABLE_SETTINGS}</div>
                            </div>
                        </div>
                    )}
                    {isIntakeSheetIdSet && (
                        <button className="btn btn-primary" data-client-id={AutomationIds.VIEW_NEW} onClick={handleClickNewButton}>
                            {languageElements.VIEW_TABLE_NEW}
                        </button>
                    )}
                </div>
            </div>
            <div className="grid-view-all">
                <Grid viewId={viewConfig.data.id} wrapText={wrapText} showFormats={showFormats} disableSetFocus={false} />
                <RSidePanel
                    controlId={AutomationIds.VIEW_SIDE_PANEL}
                    inIframe={inIframe}
                    sidePanelOpened={rightPanelRenderType === RightPanelRenderType.DETAILS}
                    onClick={handleCloseDetailsPanel}
                >
                    <button data-client-id={AutomationIds.VIEW_CLOSE} className={'close-button'} onClick={handleCloseDetailsPanel}>
                        <img src={iconClose} alt={'icon-close'} className={'icon-close'} />
                    </button>
                    <Details
                        initialTab={viewConfig.data.config?.detailsPanelInitialTab}
                        onCloseDetailsPanel={handleCloseDetailsPanel}
                        selectedRowId={selectedRowId}
                        smartsheetUsers={{
                            pageNumber: 0,
                            totalPages: 0,
                            totalCount: 0,
                            data: [],
                        }}
                        viewId={viewConfig.data.id}
                        width={CONTAINER_WIDTH}
                        detailsPanelDescription={viewConfig.data.config?.detailsPanelDescription}
                        isUnmaskedId={true}
                    />
                </RSidePanel>
                <RSidePanel
                    controlId={AutomationIds.VIEW_SIDE_PANEL}
                    inIframe={inIframe}
                    sidePanelOpened={rightPanelRenderType === RightPanelRenderType.DESCRIPTION}
                    onClick={handleCloseDescriptionPanel}
                >
                    <button data-client-id={AutomationIds.VIEW_CLOSE} className={'close-button'} onClick={handleCloseDescriptionPanel}>
                        <img src={iconClose} alt={'icon-close'} className={'icon-close'} />
                    </button>
                    <Description
                        viewDescription={viewConfig.data.description ?? ''}
                        viewOwner={viewConfig.data.ownerDetails}
                        viewId={viewConfig.data.id}
                        isLicensedUser={isLicensedUser}
                        currentUserAccessLevel={currentUserAccessLevel}
                        dataClientId={AutomationIds.DETAILS_TAB_DESCRIPTION}
                    />
                </RSidePanel>
            </div>
        </div>
    );
};

const SpinnerWrapper = styled.div`
    padding: ${sizes.xSmall}px;
    margin-left: ${sizes.small}px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    background-color: ${colors.neutralLight40};
    width: auto;
    align-items: center;
    height: 28px;
`;

const SpinnerLabel = styled.span`
    padding-left: ${sizes.small}px;
    line-height: 26px;
    text-align: center;
    color: ${colors.neutralDark30};
    font-weight: bold;
`;

export default ViewContainer;
