import { AccessLevel } from '../../../common/enums';
import { ViewShareBasic } from '../../../common/interfaces';
import * as React from 'react';
import { useMemo, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import usersClient from '../../../http-clients/usersClient';
import { isUserEligibleOrInTrial, isUserLicensedSelector } from '../../Auth/Selectors';
import { makeSelectShares } from '../Selectors';
import { AceBannerContent } from './AceBannerContent';

const areViewShareBasicObjectsEqual = (a: ViewShareBasic, b: ViewShareBasic) =>
    a.grantedToGroupId === b.grantedToGroupId && a.grantedToDomain === b.grantedToDomain && a.email === b.email && a.accessLevel === b.accessLevel;

const isNotViewOwner = (share: ViewShareBasic) => share.accessLevel === AccessLevel.ADMIN || share.accessLevel === AccessLevel.USER;

const getViewShareBasicValue = (share: ViewShareBasic) => share.email ?? share.grantedToGroupId ?? share.grantedToDomain;

export default function EvaluationSharingBanner() {
    const isUserLicensed = useSelector(isUserLicensedSelector);
    const shares = useSelector(makeSelectShares());
    const refExistingShares = useRef<ViewShareBasic[]>(shares ?? []);
    const [sharesAdded, setSharesAdded] = useState(false);
    const [adminEmail, setAdminEmail] = useState<string>();
    const isNotEligible = !useSelector(isUserEligibleOrInTrial);

    // Deep compare existing shares (refExistingShares.current) with shares in redux store and return if they are different
    const hasSharesChanged = useMemo(() => {
        if (isUserLicensed || isNotEligible) {
            return false;
        }

        const userShares = shares ?? [];
        const existingShares = refExistingShares.current;

        if (existingShares.length !== userShares.length) {
            return true;
        }

        for (let i = 0; i < existingShares.length; i++) {
            if (!areViewShareBasicObjectsEqual(existingShares[i], userShares[i])) {
                return true;
            }
        }

        return false;
    }, [isNotEligible, isUserLicensed, shares]);

    // If shares in the redux store have changed, then check if new shares where added, in which case the evaluation banner will show
    useEffect(() => {
        if (!hasSharesChanged) {
            return;
        }

        const currentNonOwnerShares = refExistingShares.current.filter(isNotViewOwner).map(getViewShareBasicValue);
        const currentNonOwnerSharesSet = new Set(currentNonOwnerShares);
        const userShares = shares ?? [];
        const sharesAddedTmp = userShares.some((share) => isNotViewOwner(share) && !currentNonOwnerSharesSet.has(getViewShareBasicValue(share)));

        setSharesAdded(sharesAddedTmp);
        refExistingShares.current = userShares;
    }, [hasSharesChanged, shares]);

    // Fetch sys-admin email for eligible user
    useEffect(() => {
        if (isUserLicensed || isNotEligible) {
            return;
        }
        usersClient
            .getAdminEmail()
            .then((email) => setAdminEmail(email))
            .catch(() => setAdminEmail(undefined));
    }, [isUserLicensed, isNotEligible]);

    if (isUserLicensed || isNotEligible || !sharesAdded) {
        return null;
    }

    // TODO: if USM plan, fetch trial end date and return <ActBannerContent endDate={dateString}>
    return <AceBannerContent adminEmail={adminEmail} />;
}
