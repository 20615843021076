import styled from '@emotion/styled';
import * as React from 'react';
import { colors, Link, useTooltip } from '@smartsheet/lodestar-core';
import { EligibilityStatus } from '../../containers/Auth/types';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { useSelector } from 'react-redux';
import { isUserEligibleOrInTrial, isUserLicensedSelector, userEligibilitySelector } from '../../containers/Auth/Selectors';
import { useEffect, useState } from 'react';
import { StyledTooltip } from '../DvStyledTooltip/StyledTooltip';
import usersClient from '../../http-clients/usersClient';
import { TrialModeOrb } from './TrialModeIndicator/TrialModeOrb';
import { TrialModeContainer } from './TrialModeIndicator/TrialModeContainer';
import { TrialModeList } from './TrialModeIndicator/TrialModeList';
import { css } from '@emotion/core';

const TOOLTIP_HOVER_TIMEOUT_MS = 400;
const EVAL_MODE_HELP_URL = 'https://help.smartsheet.com/articles/2483039-Advance-Capabilities-Evaluations';

export const TrialModeIndicator = () => {
    const languageElements = useLanguageElements();
    const authStatus = useSelector(userEligibilitySelector);
    const isUserLicensed = useSelector(isUserLicensedSelector);
    const isEligible = useSelector(isUserEligibleOrInTrial);
    const isNotEligible = !isEligible;

    const [adminEmail, setAdminEmail] = useState<string>('');

    // Fetch sys-admin email for eligible user
    useEffect(() => {
        if (isUserLicensed || isNotEligible) {
            return;
        }
        usersClient
            .getAdminEmail()
            .then((email) => setAdminEmail(email))
            .catch(() => setAdminEmail(''));
    }, [isNotEligible, isUserLicensed]);

    const { tooltipProps, targetProps } = useTooltip<HTMLDivElement>();

    const [tooltipTimer, setTooltipTimer] = useState<NodeJS.Timeout | null>(null);
    const setStateAndClearTooltipTimer =
        <T,>(setState: React.Dispatch<React.SetStateAction<T>>) =>
        (newState: T) => {
            if (tooltipTimer) {
                clearTimeout(tooltipTimer);
            }
            setTooltipTimer(null);
            setState(newState);
        };

    const [tooltipIsOpenHover, _setTooltipIsOpenHover] = useState<boolean>(false);
    const setTooltipIsOpenHover = setStateAndClearTooltipTimer(_setTooltipIsOpenHover);

    const onHover = () => {
        setTooltipIsOpenHover(true);
    };

    const onHoverOff = () => {
        const timer = setTimeout(() => {
            setTooltipIsOpenHover(false);
        }, TOOLTIP_HOVER_TIMEOUT_MS);
        setTooltipTimer(timer);
    };

    if (isEligible) {
        const activeTrial = authStatus === EligibilityStatus.ALREADY_IN_TRIAL;
        return (
            <React.Fragment>
                <AceTrialModeContainer
                    {...targetProps}
                    active={activeTrial}
                    onMouseEnter={() => {
                        onHover();
                    }}
                    onMouseLeave={() => {
                        onHoverOff();
                    }}
                >
                    <AceTrialModeOrb active={activeTrial} />
                    {languageElements.TRIAL_CHIP_LABEL}
                </AceTrialModeContainer>
                <StyledTooltip
                    {...tooltipProps}
                    placement={'bottom-end'}
                    hasArrow={false}
                    portalProps={{ zIndex: 11 }}
                    offset={{ distance: 8 }}
                    isOpen={tooltipIsOpenHover}
                    onMouseEnter={() => {
                        onHover();
                    }}
                    onMouseLeave={() => {
                        onHoverOff();
                    }}
                >
                    <TrialModeList>
                        {activeTrial ? (
                            <li> {languageElements.TRIAL_TOOLTIP_ONE_IN_TRIAL(adminEmail)} </li>
                        ) : (
                            <li> {languageElements.TRIAL_TOOLTIP_ONE_PRE_TRIAL} </li>
                        )}
                        <li>
                            {languageElements.TRIAL_TOOLTIP_TWO} <Link href={EVAL_MODE_HELP_URL}>{languageElements.TRIAL_TOOLTIP_LINK_TEXT}</Link>
                        </li>
                    </TrialModeList>
                </StyledTooltip>
            </React.Fragment>
        );
    } else {
        return <></>;
    }
};

interface ActiveProp {
    active: boolean;
}

const ActiveContainerStyles = (props: ActiveProp) => css`
    background: ${props.active ? colors.visGreenLight30 : colors.buttonDefault};
    color: ${props.active ? colors.buttonUpgrade : colors.neutralDark20};
`;

const AceTrialModeContainer = styled(TrialModeContainer)`
    ${ActiveContainerStyles};
`;

const AceTrialModeOrb = styled(TrialModeOrb)`
    background: ${(props) => (props.active ? colors.buttonUpgrade : 'transparent')};
    border-color: ${(props) => (props.active ? 'transparent' : colors.neutralDark10)};
`;
