/* eslint @typescript-eslint/no-misused-promises:"off" */
import { HttpStatusCodes } from '../common/enums';
import { AxiosError, AxiosResponse } from 'axios';
import { createAxiosInstance } from './httpClientUtil';

const handleSuccessResponse = (response: AxiosResponse) => {
    return response;
};

const handleErrorResponse = (error: AxiosError) => {
    // Redirect to log in screen as the user is unauthorized
    if (error.response && error.response.status === HttpStatusCodes.UNAUTHORIZED) {
        window.location.replace(location.href);
    }
    return Promise.reject(error);
};

const axiosInstance = createAxiosInstance({
    interceptors: {
        onSuccess: handleSuccessResponse,
        onError: handleErrorResponse,
    },
});

export default axiosInstance;
