import { StoreState } from '../../store';
import { createdStore } from '../../store/ConfigureStore';

export const getFeatureFlagBySelector = (selector: any): boolean => {
    const unsubscribe = createdStore.subscribe(() => {
        const isFeatureFlagEnabled = selector(createdStore.getState());
        if (typeof isFeatureFlagEnabled == 'boolean') {
            unsubscribe();
        }
    });
    const state: StoreState = createdStore.getState();
    return state ? selector(state) : false;
};
