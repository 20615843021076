import { useRef, useEffect } from 'react';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

function useDocumentTitle(title: string | undefined, prevailOnUnmount = false) {
    const languageElements = useLanguageElements();
    const documentTitle = title ? `${title} - ${languageElements.APPLICATION_NAME}` : languageElements.APPLICATION_NAME;
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        []
    );
}

export default useDocumentTitle;
