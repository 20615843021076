import { AxiosError, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import axiosInstance from './Axios.instance';
import { httpClientAppFeatureSelector } from '../containers/App/Selectors';
import { BACKOFF_DELAY, createAxiosInstance, DEFAULT_MAX_RETRY_ATTEMPTS, generateBackoffDuration, shouldRetry } from './httpClientUtil';
import LanguageElements from '../language-elements/LanguageElements';
import { getFeatureFlagBySelector } from '../common/utils/FeatureFlagUtils';

export const apiClient = createAxiosInstance();

// Handle errors (e.g., unauthorized redirects)
const handleErrorResponse = (error: AxiosError | Error) => {
    if (isAxiosError(error) && error.response?.status === 401) {
        window.location.replace(location.href);
    }

    return Promise.reject(error);
};

// Exponential backoff retry logic
export const fetchWithRetry = async <T>(
    requestFunc: () => Promise<AxiosResponse<T>>,
    maxRetries: number = DEFAULT_MAX_RETRY_ATTEMPTS,
    retryEnabled: boolean = true
): Promise<AxiosResponse<T>> => {
    if (!retryEnabled) {
        return await requestFunc();
    }

    let attempt = 0;
    let delay = BACKOFF_DELAY;
    let requestError: AxiosError | null = null;

    while (attempt < maxRetries) {
        try {
            return await requestFunc();
        } catch (error) {
            requestError = error as AxiosError;
            const statusCode = error.response?.status;
            if (shouldRetry(statusCode, error.status)) {
                attempt++;
                delay = generateBackoffDuration(BACKOFF_DELAY, attempt);
                await new Promise((resolve) => setTimeout(resolve, delay));
            } else {
                return handleErrorResponse(error);
            }
        }
    }
    return handleErrorResponse(requestError ?? new Error(LanguageElements.ERRORSTATE_GENERIC_MESSAGE));
};

const httpClient = {
    get: async <TResponse>(url: string, config?: AxiosRequestConfig, retryEnabled: boolean = true) => {
        if (getFeatureFlagBySelector(httpClientAppFeatureSelector)) {
            return fetchWithRetry<TResponse>(() => apiClient.get<TResponse>(url, config), DEFAULT_MAX_RETRY_ATTEMPTS, retryEnabled);
        }
        return axiosInstance.get<TResponse>(url, config);
    },

    post: async <TResponse, TData>(url: string, data: TData, config?: AxiosRequestConfig, retryEnabled: boolean = true) => {
        if (getFeatureFlagBySelector(httpClientAppFeatureSelector)) {
            return fetchWithRetry<TResponse>(() => apiClient.post<TResponse>(url, data, config), DEFAULT_MAX_RETRY_ATTEMPTS, retryEnabled);
        }
        return axiosInstance.post<TResponse>(url, data, config);
    },

    put: async <TResponse, TData>(url: string, data: TData, config?: AxiosRequestConfig, retryEnabled: boolean = true) => {
        if (getFeatureFlagBySelector(httpClientAppFeatureSelector)) {
            return fetchWithRetry<TResponse>(() => apiClient.put<TResponse>(url, data, config), DEFAULT_MAX_RETRY_ATTEMPTS, retryEnabled);
        }
        return axiosInstance.put<TResponse>(url, data, config);
    },

    patch: async <TResponse, TData>(url: string, data: TData, config?: AxiosRequestConfig, retryEnabled: boolean = true) => {
        if (getFeatureFlagBySelector(httpClientAppFeatureSelector)) {
            return fetchWithRetry<TResponse>(() => apiClient.patch<TResponse>(url, data, config), DEFAULT_MAX_RETRY_ATTEMPTS, retryEnabled);
        }
        return axiosInstance.patch<TResponse>(url, data, config);
    },

    delete: async <TResponse>(url: string, config?: AxiosRequestConfig, retryEnabled: boolean = true) => {
        if (getFeatureFlagBySelector(httpClientAppFeatureSelector)) {
            return fetchWithRetry<TResponse>(() => apiClient.delete<TResponse>(url, config), DEFAULT_MAX_RETRY_ATTEMPTS, retryEnabled);
        }
        return axiosInstance.delete<TResponse>(url, config);
    },
};

export default httpClient;
